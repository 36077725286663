/**
 * @author Valentin Tinus < val@vtinus.tk>
 */

import React from 'react';
// import * as Data from './data';
// import PPButton from './payment';
// import AddPicture from './pic-slide';
import './styles/overlay3D.css';


class LoadSpinner extends React.Component {

    constructor(props) {
        super(props);
        this.drawGraph = this.drawGraph.bind(this);
    }

    componentDidMount() {
        this.initGraph();
    }

    initGraph() {
        this.prevTime = 0;
        this.delta = 50;
        const canvas = document.querySelector(".load-spinner-graph");
        const ctx = canvas.getContext("2d");

        // var width;
        // var height;

        // const elem = document.querySelector('.load-spinner').parentElement;

        // if (elem) {
        //     width = elem.offsetWidth;
        //     height = elem.offsetHeight;
        // } else {
        // const offset = (this.props.offset || getComputedStyle(document.body).getPropertyValue("--overlay-3D-offset").replace("px", "")) * 2;
        //    const width = window.innerWidth;
        //     const height = window.innerHeight;
        // }
        if (canvas.width !== window.innerWidth || canvas.height !== window.innerHeight) {

            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            canvas.style.width = `${window.innerWidth}px`;
            canvas.style.height = `${window.innerHeight}px`;
        };

        const pointX = Math.floor(canvas.width / 2);
        const pointY = Math.floor(canvas.height / 2);
        const graphData = {
            startAngle1: Math.random() * 2, startAngle2: Math.random() * 2, endAngle: Math.random() * 2, angleInc: 0, ccw: false, jitter: 0.05, pointX: pointX,
            pointY: pointY, rect: { top: -1 * pointY, left: -1 * pointX, right: canvas.width, bottom: canvas.height }
        };
        ctx.translate(graphData.pointX, graphData.pointY);
        window.requestAnimationFrame((currentTime) => this.drawGraph(ctx, currentTime, graphData, true));
    }

    drawGraph(ctx, currentTime, graphData, ani) {
        if (this.prevTime === 0) {
            this.prevTime = currentTime;
        }
        if (ani) {
            ctx.strokeStyle = "rgba(0, 0, 0, 0.12)";
            ctx.lineWidth = 30;
            ctx.clearRect(graphData.rect.left, graphData.rect.top, graphData.rect.right, graphData.rect.bottom);

            let angle = graphData.startAngle1;

            ctx.beginPath();
            let radius = 150;
            ctx.arc(0, 0, 150, angle * Math.PI, (1.66 + angle) * Math.PI);
            // angle = (1.66 + angle) * Math.PI;
            // radius = 130;
            // ctx.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
            ctx.stroke();
            angle = graphData.startAngle2;
            radius = 120;
            ctx.beginPath();
            ctx.arc(0, 0, radius, angle * Math.PI, (1.6 + angle) * Math.PI);
            // angle = (1.6 + angle) * Math.PI;
            // radius = 100;
            // ctx.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
            ctx.stroke();

            ctx.strokeStyle = "beige";
            ctx.lineWidth = 3;
            if (Math.abs(graphData.angleInc) <= graphData.endAngle) {
                if (graphData.ccw) {
                    graphData.angleInc -= 0.02 / (1 - Math.abs(graphData.angleInc) / graphData.endAngle);
                } else {
                    graphData.angleInc += 0.02 / (1 - Math.abs(graphData.angleInc) / graphData.endAngle);
                }
            } else {
                graphData.angleInc = 0;

                graphData.startAngle1 = Math.random() * 2;
                graphData.startAngle2 = Math.random() * 2;
                graphData.endAngle = Math.random() * 2 + 1;
                graphData.jitter = Math.random() * 0.8 + 0.1;
                graphData.ccw = !graphData.ccw;
            }

            angle = graphData.startAngle1 + graphData.angleInc;

            ctx.beginPath();
            radius = 150;
            ctx.arc(0, 0, 150, angle * Math.PI, (1.66 + angle) * Math.PI);
            // angle = (1.66 + angle) * Math.PI;
            // radius = 130;
            // ctx.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
            ctx.stroke();
            angle = graphData.startAngle2 + graphData.angleInc * graphData.jitter;
            radius = 120;
            ctx.beginPath();
            ctx.arc(0, 0, radius, angle * Math.PI, (1.6 + angle) * Math.PI);
            angle = (1.6 + angle) * Math.PI;
            radius = 100;
            ctx.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
            ctx.stroke();

        };
        let shouldRedraw = false;
        if (currentTime - this.prevTime >= this.delta) {
            this.prevTime = currentTime;
            shouldRedraw = true;
        }

        window.requestAnimationFrame((currentTime) => this.drawGraph(ctx, currentTime, graphData, shouldRedraw));
    }

    render() {
        return (<div className="load-spinner">
            <h2>{this.props.msg}</h2>
            <canvas className="load-spinner-graph" width='2' height='2'></canvas>
        </div>)
    }
}

export default LoadSpinner;