import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect,
} from "react-router-dom";
import LoadSpinner from './load-spinner';
import './styles/index.css';

// import Book from './book';

const Menu3d = React.lazy(() => import('./3dview'));
const Book = React.lazy(() => import('./book'));
const BlogPosts = React.lazy(() => import('./blogs'));
const AboutUs = React.lazy(() => import('./about'));
const AddCollections = React.lazy(() => import('./photos'));
const DrawingCollections = React.lazy(() => import('./drawings'));
const Legal = React.lazy(() => import('./legal'));
const Error = React.lazy(() => import('./error'));
// const Videos = React.lazy(() => import('./video'));

if (window.innerHeight < window.innerWidth) {
  window.sidebarVisible = true;
} else {
  window.sidebarVisible = false;
}

window.addEventListener('resize', windowResized);

function windowResized() {
  //compare browser with with recomended one
  const body = document.body;
  const recommendedWidth = parseInt(getComputedStyle(body).getPropertyValue('--recommended-width').replace('px', ''));
  if (window.innerWidth > recommendedWidth) {
    const sideWidth = `${Math.round((window.innerWidth - recommendedWidth) / 2)}px`;
    window.sidesPresent = true;
    body.style.setProperty('--side-width', sideWidth);
    body.classList.add('sides-present');
    body.classList.remove('sides-not-present');

    const docName = window.location.pathname.split("/").pop();
    if (window.sidesPresent && docName !== "drawings") {

      window.scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
      if (window.scrollBarWidth) {
        document.body.style.setProperty('--scroll-bar-width', `${window.scrollBarWidth}px`);
      }
    }
  }
  else {
    window.sidesPresent = false;
    body.style.setProperty('--side-width', '0px');
    body.classList.add('sides-not-present');
    body.classList.remove('sides-present');
  }
}

window.isMobile = (/Mobi|Android/i.test(navigator.userAgent));
windowResized();

//TODO:
//authorization takes little bit longer sometimes
ReactDOM.render(
  <React.StrictMode>
    {/* <Suspense fallback={<h2 className="chapter-name"><i className="fas fa-spinner item-loading"></i> </h2>}> */}
    <Suspense fallback={<LoadSpinner offset={0} msg="loading page" />}>
      <Router>
        <Switch>
          {window.isMobile ?
            <Route exact path="/"> <Redirect to="/about" /></Route>
            :
            <Route exact path="/"> <Redirect to="/model" /></Route>
          }
          {/* {window.isMobile ? <Route path="/model" component={AboutUs} /> : <Route path="/model" component={Menu3d} />} */}
          <Route path="/model" component={Menu3d} />
          <Route path="/book" component={Book} />
          <Route path="/home" component={Book} />
          <Route path="/blog-posts" component={() => <BlogPosts sidesPresent={window.sidesPresent} />} />
          <Route path="/about" component={AboutUs} />
          <Route path="/photos" component={AddCollections} />
          <Route path="/drawings" component={DrawingCollections} />
          <Route path="/legal" component={Legal} />
          {/* <Route path="/videos" component={Videos} /> */}
          <Route component={Error} />
        </Switch>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),/* can be function here */
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
